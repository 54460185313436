<template>
  <section>
    <p class="headInfo">
      <span>Зарегистрируйтесь в сервисе Export-Base в один клик, просто указав свой E-mail.</span><br>
      <span>Регистрация даст вам ряд преимуществ:</span>
    </p>
    <div class="mainInfo">
      <div>
        <img src="./img/discount.png" width="101" height="100" alt="">
        <p class="head">Система Скидок</p>
        <p>
          после регистрации вы получаете все преимущества клубной системы ExportBase.
          Накапливайте баллы и получайте <b>скидку до 50%</b> на последующие выгрузки.
        </p>
      </div>
      <div>
        <img src="./img/history.png" width="100" height="100" alt="">
        <p class="head">История Скачиваний</p>
        <p>
          вы сможете контролировать все ваши заказы, менять и конфигурировать выгрузки баз предприятий,
          а также добавлять новые фильтры.
        </p>
      </div>
      <div>
        <img src="./img/update.png" width="100" height="100" alt="">
        <p class="head">Обновление базы</p>
        <p>
          ежемесячное обновление уже купленных баз со скидкой <b>до 80%.</b>
          Экономьте деньги и получайте самые свежие данные.
        </p>
      </div>
    </div>
    <div class="arrow">
      <img src="./img/arrow.png" width="18" height="20" alt=""/>
    </div>
    <div class="emailBlock" :class="emailValidClass">
      <input type="text" @input="handleEmailChange($event.target.value)" :value="email" maxlength="100"
             placeholder="Ваш E-mail" name="email" @keyup.enter="register"/>
    </div>
    <transition name="appear">
      <div class="passwordRecovery" v-show="passwordRecoveryBlockVisible">
        <span>
          Если эта ваша почта, то вы можете <span class="recover" @click="recoverPassword">восстановить пароль.</span>
        </span>
      </div>
    </transition>
    <div class="arrow">
      <img src="./img/arrow.png" width="18" height="20" alt=""/>
    </div>
    <div class="registrationButtonBlock" @click="register">
      <span>Регистрация</span>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: 'Registration',

  data() {
    return {
      allowCheckEmail: false,
      passwordRecoveryBlockVisible: false,
    };
  },

  computed: {
    emailValidClass() {
      if (!this.allowCheckEmail) {
        return '';
      }

      return this.isValidEmail ? 'valid' : 'inValid';
    },

    isValidEmail() {
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/.test(this.email);
    },

    ...mapState(['email']),
  },

  methods: {
    handleEmailChange(email) {
      this.allowCheckEmail = true;
      this.setEmail(email.replace(/ /g, ''));
    },

    userRegisterHandler(state) {
      switch (state) {
        case 'done':
          echo('Письмо с логином и паролем отправлено на почту.');

          const needle = 'bonus_for_new_client=';
          const index = location.href.indexOf(needle);

          if (index !== -1) {
            location.href = `/auth?${location.href.substring(index)}`;
          } else {
            location.href = '/auth';
          }

          break;

        case 'limit_of_tries':
          echo('Количество попыток зарегистрироваться исчерпано! Попробуйте позднее.');
          break;

        case 'email_exist':
          echo('Такой e-mail уже существует!');
          this.passwordRecoveryBlockVisible = true;
          break;
      };
    },

    async register() {
      this.allowCheckEmail = true;

      if (this.isValidEmail) {
        this.userRegisterHandler(await this.registerUser());
      }
    },

    // #todo Не самое элегантное решение, возможно лучше переделать со временем.
    recoverPassword() {
      document.querySelector('div.authorization').click();

      const interval = setInterval(() => {
        if (!!document.querySelector('span.forgotPassword')) {
          document.querySelector('span.forgotPassword').click();
          clearInterval(interval);
        }
      }, 50);
    },

    ...mapMutations(['setEmail']),
    ...mapActions(['registerUser']),
  },

  mounted() {
    if (this.email !== '') {
      this.allowCheckEmail = true;
    }
  }
};
</script>

<style scoped lang="less">
section {
  display: flex;
  flex-direction: column;
  margin: 20px 30px 0 30px;
  font-size: 13px;

  .headInfo {
    text-align: center;
  }

  .mainInfo {
    display: flex;
    margin: 40px 0 20px;

    div {
      padding: 0 10px;
    }

   .head {
     margin-bottom: 10px;
     font-weight: bold;
    }
  }

  .arrow {
    text-align: center;
  }

  .emailBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    width: 45%;

    input {
      width: 195px;
      height: 35px;
      border: 1px solid rgba(133, 133, 133, 0.7);
      padding-left: 5px;
    }

    &.valid {
      background: url('./img/done.png') no-repeat center right;

      & input {
        border: 1px solid #B0D638;
        box-shadow: #b0d638 0 0 4px;
      }
    }

    &.inValid {
      background: url('./img/fail.png') no-repeat center right;

      & input {
        border: 1px solid #D21C1C;
        box-shadow: #d21c1c 0 0 4px;
      }
    }
  }

  .registrationButtonBlock {
    display: flex;
    justify-content: center;

    span {
      padding: 9px 10px;
      margin: 15px 0;
      cursor: pointer;
      height: 22px;
      width: 140px;
      text-align: center;
      background: var(--color-button-prime);
      border-radius: 5px;
      color: white;
      font-size: 20px;

      &:hover {
        background: var(--color-button-prime-hover);
      }
    }
  }

  .passwordRecovery {
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;

    .recover {
      border-bottom: 1px dashed #000;
      cursor: pointer;
    }
  }

  .appear-enter-active, .appear-leave-active {
    transition: opacity 2s linear, max-height 0.6s linear;
    opacity: 1;
    max-height: 14px;
  }

  .appear-enter, .appear-leave-to {
    max-height: 0;
    opacity: 0;
  }
}
</style>
