<template>
  <VueFinalModal
    class="modal-container"
    content-class="modal-content"
  >
    <div class="modal-wrapper">
      <button
        class="close-button"
        @click="closeModal"
      >
        <img
          src="./img/closeIcon.png"
          alt="close"
          width="30"
          height="28"
        >
      </button>

      <section>
        <div class="switch">
          <div
            class="authorization"
            :class="{'not-chosen': !isAuthComponent}"
            @click="switchTab('Authorization')"
          >
            <img
              src="./img/authKey.png"
              width="19"
              height="15"
              alt=""
            >
            <span>Авторизация</span>
          </div>
          <div
            class="registration"
            :class="{'not-chosen': !isRegComponent}"
            @click="switchTab('Registration')"
          >
            <img
              src="./img/reg.png"
              width="19"
              height="14"
              alt=""
            >
            <span>Регистрация</span>
          </div>
        </div>

        <keep-alive>
          <transition
            name="switch"
            mode="out-in"
          >
            <component :is="currentTabComponent" />
          </transition>
        </keep-alive>
      </section>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import Authorization from './components/authorization/Authorization.vue';
import Registration from './components/registration/Registration.vue';

export default {
  name: 'AuthWrapperContent',
  components: {
    VueFinalModal,
    Authorization,
    Registration,
  },

  data() {
    return {
      showModal: false,
      currentTab: 'Authorization',
    };
  },

  computed: {
    currentTabComponent() {
      return this.currentTab;
    },

    isAuthComponent() {
      return this.currentTab === 'Authorization';
    },

    isRegComponent() {
      return this.currentTab === 'Registration';
    },
  },

  mounted() {
    if (location.hash.includes('#reg') && !location.hash.includes('#reg_date')) {
      this.switchTab('Registration');
    }
  },

  methods: {
    switchTab(tab) {
      this.currentTab = tab;
    },

    closeModal() {
      document.dispatchEvent(new Event('closeAuthWrapper'));
    },
  },
};
</script>

<style src="../../../../../../css/elements.css"></style>

<style scoped lang="less">

.modal-wrapper {
  position: relative;
  padding-bottom: 20px;
  width: 670px;
}

.close-button {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 1;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.switch {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 40px;

  section {
    display: flex;
    flex-direction: column;
    color: black;
  }

  .authorization,
  .registration {
    width: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .not-chosen {
    background-color: #eee;

    span {
      border-bottom: 1px dashed;
    }
  }

  span {
    margin-left: 10px;
  }
}

.switch-enter-active,
.switch-leave-active {
  transition: opacity 0.3s linear;
}

.switch-enter,
.switch-leave-to {
  opacity: 0;
}

@media (max-width: 800px) {
  .modal-content {
    width: 100%;
    margin: 0 1rem;
  }
}
</style>
