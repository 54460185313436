<template>
  <section>
    <span>Логин и пароль были высланы на email, указанный при заказе базы или регистрации.</span>
    <div class="authRecoverWrapper">
      <div class="authRecoverBlock">
        <form
          v-show="isAuth && !isInfoBlock"
          class="auth"
        >
          <div
            class="emailBlock"
            :class="emailValidClass"
          >
            <span>E-mail:</span>
            <input
              type="text"
              name="email"
              :value="email"
              maxlength="100"
              @input="handleEmailChange($event.target.value)"
              @keyup.enter="authorize"
            >
          </div>
          <div
            class="passwordBlock"
            :class="passwordValidClass"
          >
            <span>Пароль:</span>
            <input
              type="password"
              name="password"
              :value="password"
              maxlength="50"
              @input="handlePasswordChange($event.target.value)"
              @keyup.enter="authorize"
            >
          </div>
          <div class="entranceBlock">
            <span
              class="entrance"
              @click="authorize"
            >Вход</span>
            <span
              class="forgotPassword"
              @click="setAction('recovery')"
            >Забыли пароль?</span>
          </div>
        </form>
        <div
          v-show="isRecovery && !isInfoBlock"
          class="recovery"
        >
          <div
            class="emailBlock"
            :class="emailValidClass"
          >
            <span>E-mail:</span>
            <input
              type="text"
              :value="email"
              maxlength="100"
              name="email"
              @input="handleEmailChange($event.target.value)"
              @keyup.enter="recovery"
            >
          </div>
          <div class="recoveryBlock">
            <span
              class="recovery"
              @click="recovery"
            >Восстановить пароль</span>
            <span
              class="turnToAuth"
              @click="setAction('auth')"
            >
              <span>Вернуться к авторизации</span>
              <img
                src="./img/backToAuth.png"
                width="24"
                height="25"
                alt=""
              >
            </span>
          </div>
        </div>
        <!-- #todo Вынести верстку информационных частей в один блок -->
        <div
          v-show="authInProcess"
          class="authInProcess"
        >
          <img
            src="./img/authInProcess.gif"
            width="32"
            height="32"
            alt=""
          >
          <span>ЛК загружается, может занять 1-2 минуты.</span>
        </div>
        <div
          v-show="failureAuth"
          class="failureAuth"
        >
          <span>Пароль/логин неверен!</span>
        </div>
        <div
          v-show="recoveryInfoIsVisible"
          class="recoveryResult"
        >
          <span
            v-if="!failureRecovery"
            class="success"
          >Пароль выслан на email</span>
          <span
            v-else
            class="fail"
          >Учетной записи с этим email не существует</span>
        </div>
      </div>
      <div class="lockImage">
        <img
          src="./img/authSprite.png"
          :class="lockImageClass"
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Authorization',

  data() {
    return {
      action: 'auth',
      allowCheckEmail: false,
      allowCheckPassword: false,
      failureAuth: false,
      failureRecovery: false,
      recoveryInfoIsVisible: false,
    };
  },

  computed: {
    isAuth() {
      return this.action === 'auth';
    },

    isRecovery() {
      return this.action === 'recovery';
    },

    isValidEmail() {
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/.test(this.email);
    },

    isValidPassword() {
      return this.password.length > 0;
    },

    emailValidClass() {
      if (!this.allowCheckEmail) {
        return '';
      }

      return this.isValidEmail ? 'valid' : 'inValid';
    },

    passwordValidClass() {
      if (!this.allowCheckPassword) {
        return '';
      }

      return this.isValidPassword ? 'valid' : 'inValid';
    },

    lockImageClass() {
      if (this.allowAuthorize) {
        return 'valid';
      }

      if (this.isValidEmail || this.isValidPassword) {
        return 'halfValid';
      }

      return '';
    },

    allowAuthorize() {
      return this.isValidEmail && this.isValidPassword;
    },

    allowRecovery() {
      return this.isValidEmail;
    },

    isInfoBlock() {
      return this.failureAuth || this.authInProcess || this.recoveryInfoIsVisible;
    },

    ...mapState([
      'email',
      'password',
      'isPersonalCabinet',
      'authInProcess',
    ]),
  },

  mounted() {
    if (this.email !== '') {
      this.allowCheckEmail = true;
    }

    if (this.password !== '') {
      this.allowCheckPassword = true;
    }
  },

  unmounted() {
    window.open_balance_block = false;
  },

  methods: {
    handleEmailChange(email) {
      this.allowCheckEmail = true;
      this.setEmail(email.replace(/ /g, ''));
    },

    handlePasswordChange(password) {
      this.allowCheckPassword = true;
      this.setPassword(password);
    },

    setAction(type) {
      this.action = type;
    },

    allowCheckAllInput() {
      this.allowCheckEmail = true;
      this.allowCheckPassword = true;
    },

    successAuthHandler() {
      if (this.isPersonalCabinet) {
        location.href = '/auth';
      } else {
        document.dispatchEvent(new Event('closeAuthWrapper'));
        echo('Вы успешно авторизовались!', 3000, 'success');

        // Вызов для обновления состояния авторизация компонента новых компаний.
        if (typeof (window.vue_new_company_configurator) !== 'undefined') {
          window.vue_new_company_configurator.$store.dispatch('summary/setSubscriptionInitialData');
        }
      }
    },

    failAuthHandler() {
      this.failureAuth = true;

      setTimeout(() => {
        this.failureAuth = false;
      }, 2000);
    },

    recoveryResultHandler() {
      this.showRecoveryInfo();

      if (!this.failureRecovery) {
        this.setAction('auth');
      }
    },

    showRecoveryInfo() {
      this.recoveryInfoIsVisible = true;

      setTimeout(() => {
        this.recoveryInfoIsVisible = false;
      }, 3000);
    },

    async authorize() {
      this.allowCheckAllInput();

      if (this.allowAuthorize) {
        if (await this.authorizeClient()) {
          // переменная отвечающая за открытие окна оплаты после авторизации
          if (window.open_balance_block) {
            const event = new Event('open-balance-block');
            document.body.dispatchEvent(event);
          }
          this.successAuthHandler();
        } else {
          this.failAuthHandler();
        }
      }
    },

    async recovery() {
      this.allowCheckEmail = true;

      if (this.allowRecovery) {
        this.failureRecovery = !await this.recoveryPassword();
        this.recoveryResultHandler();
      }
    },

    ...mapMutations([
      'setEmail',
      'setPassword',
    ]),

    ...mapActions([
      'authorizeClient',
      'recoveryPassword',
    ]),
  },
};
</script>

<style scoped lang="less">
section {
  display: flex;
  flex-direction: column;
  margin: 20px 35px 0 35px;
}

.authRecoverWrapper {
  display: flex;
  margin-top: 30px;

  .authRecoverBlock, .lockImage {
    width: 50%;
  }

  .authRecoverBlock {
    input {
      width: 195px;
      height: 35px;
      border: 1px solid rgba(133, 133, 133, 0.7);
      padding-left: 5px;
      margin-right: 30px;
    }
  }

  .emailBlock, .passwordBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .lockImage {
    width: 150px;
    height: 150px;
    overflow-y: hidden;
    margin-left: 80px;
    bottom: 15px;

    img {
      width: 100%;
      position: relative;
    }

    & .valid {
      top: -200%;
    }

    & .halfValid {
      top: -100%;
    }
  }

  .entranceBlock {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0 0 35px;

    .entrance {
      padding: 8px 16px;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: var(--color-button-prime);
      border-radius: 5px;
      color: white;
      font-size: 20px;
    }

    .forgotPassword {
      border-bottom: 1px dashed;
      cursor: pointer;
    }
  }
}

.emailBlock.valid, .passwordBlock.valid {
  background: url('./img/done.png') no-repeat center right;

  & input {
    border: 1px solid #B0D638;
    box-shadow: #b0d638 0 0 4px;
  }
}

.emailBlock.inValid, .passwordBlock.inValid {
  background: url('./img/fail.png') no-repeat center right;

  & input {
    border: 1px solid #D21C1C;
    box-shadow: #d21c1c 0 0 4px;
  }
}

.authInProcess {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  justify-content: space-evenly;

  span {
    margin-bottom: 35px;
  }
}

.failureAuth, .recoveryResult {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #b22222;

  span {
    margin-bottom: 40px;
  }
}

.recoveryBlock {
  display: flex;
  flex-direction: column;
  margin-left: 23%;

  .recovery {
    background: var(--color-button-prime);
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
    width: 165px;
    text-align: center;
  }

  .turnToAuth {
    span {
      cursor: pointer;
      color: #1b88c1;
      border-bottom: 1px dashed #1b88c1;
    }

    img {
      vertical-align: middle;
    }
  }
}

.recoveryResult {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  .success {
    color: green;
  }

  .fail {
    color: #b22222;
  }
}
</style>
