<template>
  <div />
</template>

<script setup>
import { onBeforeUnmount, onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import AuthWrapperContent from './AuthWrapperContent.vue';

const { open, close } = useModal({
  component: AuthWrapperContent,
});

onMounted(() => {
  document.addEventListener('openAuthWrapper', open);
  document.addEventListener('closeAuthWrapper', close);
});

onBeforeUnmount(() => {
  document.removeEventListener('closeAuthWrapper', close);
  document.removeEventListener('openAuthWrapper', open);
});
</script>

<style>
</style>
