import { createStore } from 'vuex';
import api from '../api/api';
import { catchError } from '../api/helper';

const store = createStore({
  state: {
    authorized: false,
    balance: 0,
    email: '',
    password: '',
    updates: 0,
    promocodes: [],
    outdatedOrders: [],
    isPersonalCabinet: false,

    incorrectAuthData: false,

    authInProcess: false,

    hashForAffiliateProgram: '',
  },

  getters: {
    getLinkTarget(state) {
      return state.isPersonalCabinet ? '' : '_blank';
    },
    getEmail(state) {
      return state.email;
    }
  },

  mutations: {
    setAuthorization(state, value) {
      state.authorized = value;
    },

    setBalance(state, value) {
      state.balance = value;
    },

    setEmail(state, email) {
      state.email = email;
    },

    setPassword(state, password) {
      state.password = password;
    },

    setUpdates(state, updates) {
      state.updates = updates;
    },

    setPromocodes(state, promocodes) {
      state.promocodes = promocodes;
    },

    setOutdatedOrders(state, outdatedOrders) {
      state.outdatedOrders = outdatedOrders;
    },

    setIsPersonalCabinet(state, isCabinet) {
      state.isPersonalCabinet = isCabinet;
    },

    setIncorrectAuthData(state, condition) {
      state.incorrectAuthData = condition;
    },

    setAuthInProcess(state, authState) {
      state.authInProcess = authState;
    },

    setHashForAffiliateProgram(state, hash) {
      state.hashForAffiliateProgram = hash;
    },
  },

  actions: {
    async setClientAuthorization({ commit, dispatch }) {
      try {
        commit('setAuthorization', Boolean(await api.isClientAuthenticated()));
        dispatch('setClientData');
      } catch (error) {
        catchError(error);
      }
    },

    async setClientData({ state, commit }) {
      try {
        if (state.authorized) {
          commit('setEmail', await api.getClientEmail());
          commit('setUpdates', await api.getClientUpdates());
          commit('setBalance', await api.getClientBalance());
          commit('setOutdatedOrders', await api.getClientOutdatedOrders());
        }
        commit('setPromocodes', await api.getClientPromocodes());
      } catch (error) {
        catchError(error);
      }
    },

    async updateBalance({ commit }) {
      try {
        commit('setBalance', await api.getClientBalance());
      } catch (error) {
        catchError(error);
      }
    },

    async authorizeClient({ state, commit, dispatch }) {
      try {
        commit('setAuthInProcess', true);
        const result = await api.authorizeClient(state.email, state.password);
        commit('setAuthInProcess', false);

        if (result.type === 'exportbase_user') {
          commit('setAuthorization', true);
          dispatch('setClientData');
          return true;
        }
      } catch (error) {
        catchError(error);
      }
    },

    async recoveryPassword({ state }) {
      try {
        return await api.recoveryPassword(state.email);
      } catch (error) {
        catchError(error);
      }
    },

    async registerUser({ state }) {
      try {
        return await api.registerUser(state.email, state.hashForAffiliateProgram);
      } catch (error) {
        catchError(error);
      }
    },
  },
});

export default store;
