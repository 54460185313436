<template>
  <div>
    <transition
      mode="out-in"
      name="appear"
    >
      <div
        v-show="authorizedQueryDone"
        class="personalCabinet"
      >
        <AuthWrapper />
        <section v-if="authorized">
          <Authorized />
        </section>
        <section v-else>
          <UnAuthorized />
        </section>
        <UserBenefits v-if="showPromocodesPanel" />
      </div>
    </transition>
    <ModalsContainer />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';
import UnAuthorized from './components/unAuthorized/UnAuthorized.vue';
import Authorized from './components/authorized/Authorized.vue';
import AuthWrapper from './components/modals/authWrapper/AuthWrapper.vue';
import UserBenefits from './components/userBenefits/UserBenefits.vue';

export default {
  name: 'HeaderPersonalCabinet',

  components: {
    ModalsContainer,
    UserBenefits,
    UnAuthorized,
    Authorized,
    AuthWrapper,
  },

  data() {
    return {
      authorizedQueryDone: false,
    };
  },

  computed: {
    ...mapState([
      'authorized',
      'promocodes',
      'outdatedOrders',
    ]),

    isRegLocation() {
      return (
        window.location.href.includes('#reg')
        && !window.location.href.includes('#reg_date')
      );
    },

    isAuthLocation() {
      return window.location.href.includes('#auth');
    },

    showPromocodesPanel() {
      return (this.promocodes.length > 0 || this.outdatedOrders.length > 0);
    },
  },

  async created() {
    await this.setClientAuthorization();
    this.authorizedQueryDone = true;
    this.locationHandler();
  },

  methods: {
    ...mapMutations([
      'setIsPersonalCabinet',
      'setHashForAffiliateProgram',
    ]),

    ...mapActions(['setClientAuthorization']),

    locationHandler() {
      if (window.location.href.includes('auth')) {
        this.setIsPersonalCabinet(true);
      }

      if (
        (this.isAuthLocation || this.isRegLocation)
        && !this.authorized && window.location.pathname !== '/auth'
      ) {
        document.dispatchEvent(new Event('openAuthWrapper'));
      }

      if (window.location.hash.length === 37) {
        this.setHashForAffiliateProgram(window.location.hash.slice(-32));
      }
    },
  },
};
</script>

<style scoped>
.personalCabinet {
  color: #fff;
  padding-left: 15px;
  height: 24px;
  align-content: center;
}

.appear-enter-active, .appear-leave-active {
  transition: all 0.3s linear;
  opacity: 1;
}

.appear-enter, .appear-leave-to {
  opacity: 0;
}
</style>
