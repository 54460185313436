<template>
  <Popup
    v-if="visible"
    @close="closePopup"
  >
    <template #content>
      <div
        v-if="outdatedOrders.length === 0 || promocodes.length > 0"
        class="promocodes"
      >
        <h3 class="promocodes__title">
          Доступные вам промокоды
        </h3>
        <div
          v-if="promocodes.length > 0"
          class="promocodes-list"
        >
          <PromocodeInfo
            v-for="promocode in orderedPromocodes"
            :key="promocode.promocode"
            :discount="promocode.discount"
            :end-time="getFormattedDate(promocode.end_time)"
            :code="promocode.promocode"
            :min-cost="promocode.min_cost"
          />
          <span class="about">
            Сегодня вам доступны промокоды, используйте их при оплате Базы данных и получите скидку.
          </span>
        </div>
        <div
          v-else
          class="no-promocodes"
        >
          Нет доступных промокодов
        </div>
      </div>

      <div
        v-if="outdatedOrders.length > 0"
        class="updates"
      >
        <h3 class="updates__title">
          Доступные обновления
        </h3>
        <div class="updates-list">
          <span class="about">
            Сегодня вам доступно обновление следующих заказов:
          </span>
          <UpdatingOrderInfo
            v-for="outdatedOrder in outdatedOrders"
            :key="outdatedOrder.id"
            :order-id="outdatedOrder.id"
            :payment-date="outdatedOrder.payment_time"
            :type="outdatedOrder.type"
            :count-companies="outdatedOrder.count"
            :discount="outdatedOrder.updateInfo.discount"
            :strategy="outdatedOrder.updateInfo.strategy"
            :total-count="outdatedOrder.updateInfo.totalCount"
            :update-count="outdatedOrder.updateInfo.newCount"
            :update-cost="outdatedOrder.updateInfo.newCost"
            @close="closePopup"
          />
        </div>
        <div
          class="eb-button-prime"
          title="Перейти к обновлению заказов"
          @click="goToReadyToUpdateOrders"
        >
          Всего обновлений: {{ updates }}
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import ru from 'dayjs/locale/ru';
import dayjs from 'dayjs';
import Popup from '../../../../../../common_components/Popup/Popup.vue';
import PromocodeInfo from './PromocodeInfo.vue';
import UpdatingOrderInfo from './UpdatingOrderInfo.vue';

export default {
  name: 'UserBenefitsPopup',
  components: {
    PromocodeInfo,
    UpdatingOrderInfo,
    Popup,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },

    updates: {
      type: Number,
      required: false,
      default: 0,
    },

    promocodes: {
      type: Array,
      required: true,
    },

    outdatedOrders: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },

  emits: ['close'],

  computed: {
    orderedPromocodes() {
      return [...this.promocodes]
        .sort((a, b) => (new Date(a.end_time)).getTime() - (new Date(b.end_time)).getTime());
    },
  },

  methods: {
    closePopup() {
      this.$emit('close');
    },

    getFormattedDate(date) {
      return dayjs(date).locale(ru).format('D MMM YYYY');
    },

    goToReadyToUpdateOrders() {
      window.location.href = '/auth#ready_to_update';
      this.$emit('close');
    },
  },
};
</script>

<style scoped src="../../../../../../../css/elements.css"></style>

<style scoped>
.updates, .promocodes {
  width: 520px;
  display: flex;
  flex-direction: column;
  padding: 2px 5px;
  gap: 15px;
  align-items: center;
}

.updates__title, .promocodes__title {
  font-size: 18px;
  font-weight: bold;
}

.updates-list, .promocodes-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>
