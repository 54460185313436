import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import store from './store/store';
import HeaderPersonalCabinet from './HeaderPersonalCabinet.vue';

const app = createApp(HeaderPersonalCabinet);
const vfm = createVfm();

initSentry(app);

app.use(store);
app.use(vfm);

window.vue_header_personal_cabinet = app.mount('#vue_header_personal_cabinet');
