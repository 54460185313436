import axios from 'axios';

export default {
  async isClientAuthenticated() {
    return (await axios.get('/api/client_is_authenticated')).data;
  },

  async getClientBalance() {
    return (await axios.get('/api/personal_cabinet/balance')).data;
  },

  // #todo Вынести метод для получение email в общий метод api из компонента.
  async getClientEmail() {
    return (await axios.get('/api/component/cashless_payment_form/user_email')).data;
  },

  async getClientUpdates() {
    return (await axios.get('/api/personal_cabinet/updates')).data;
  },

  async getClientOutdatedOrders() {
    return (await axios.get('/api/lk/order', {
      params: {
        offset: 0,
        limit: 2,
        type: 'ready_to_update',
      },
    })).data;
  },

  async getClientPromocodes() {
    return (await axios.get('/api/personal_cabinet/promocodes')).data;
  },

  // #todo Написать запросы к api на авторизацию вместо использования старого кода?
  async authorizeClient(mail, password) {
    const params = new URLSearchParams();
    params.append('mail', mail);
    params.append('password', password);

    return (await axios.post('/include/authorization_clients.php', params)).data;
  },

  async recoveryPassword(email) {
    const res = await axios.get(`/api/personal_cabinet/password_recovery?email=${email}`);

    return res.status === 200;
  },

  async registerUser(email, hash) {
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('hash', hash);

    return (await axios.post('/handlers/reg_handler.php', params)).data;
  },
};
