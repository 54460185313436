<template>
  <div>
    <div
      class="promoCodesOpener"
      @click="openUserBenefitsPopup"
    >
      {{ getName }}
    </div>
    <UserBenefitsPopup
      :visible="openPromocodesPopup"
      :updates="updates"
      :promocodes="promocodes"
      :outdated-orders="outdatedOrders"
      @close="closeUserBenefitsPopup"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserBenefitsPopup from '../../../../lk/components/LkContent/InfoPanel/components/user_benefits_popup/UserBenefitsPopup.vue';

export default {
  name: 'UserBenefits',
  components: {
    UserBenefitsPopup,
  },

  data() {
    return {
      openPromocodesPopup: false,
    };
  },

  computed: {
    ...mapState([
      'updates',
      'promocodes',
      'outdatedOrders',
    ]),

    getName() {
      if (this.outdatedOrders.length === 0 && this.promocodes.length > 0) {
        return 'ПРОМОКОДЫ';
      }
      if (this.outdatedOrders.length > 0 && this.promocodes.length === 0) {
        return 'ОБНОВЛЕНИЯ ЗАКАЗОВ';
      }
      return 'ОБНОВЛЕНИЯ И ПРОМОКОДЫ';
    },
  },

  methods: {
    openUserBenefitsPopup() {
      this.openPromocodesPopup = true;
      window.MFuncs.yandexReachGoalWithTarget('openPromoCodesAndUpdatesPopup');
    },

    closeUserBenefitsPopup() {
      this.openPromocodesPopup = false;
    },
  },
};
</script>

<style scoped>
.promoCodesOpener {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 150%;
  transform: rotate(-90deg);
  transform-origin: right bottom;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  background: linear-gradient(to right, #88a148, #b4bd70);
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 1240px) {
  .promoCodesOpener {
    display: none;
  }
}
</style>
