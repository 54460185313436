<template>
  <div class="unAuthorized">
    <a
      href="/auth"
      :target="getLinkTarget"
      class="title"
      :class="[{underline: !isPersonalCabinet}]"
    >Личный кабинет</a>
    <svg
      class="user-image"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 8.05798C7.00832 8.05798 6.2833 7.83486 5.66661 7.41683C5.04993 6.9988 4.56928 6.40464 4.28545 5.70949C4.00163 5.01434 3.92736 4.24941 4.07206 3.51144C4.21675 2.77347 4.5739 2.0956 5.09835 1.56355C5.6228 1.0315 6.29098 0.669173 7.01841 0.522381C7.74584 0.375589 8.49984 0.450928 9.18506 0.73887C9.87029 1.02681 10.456 1.51443 10.868 2.14005C11.2801 2.76567 11.5 3.5012 11.5 4.25363C11.5 5.2626 11.1049 6.23026 10.4017 6.94371C9.69839 7.65716 8.74456 8.05798 7.75 8.05798ZM7.75 1.97102C7.30499 1.97102 6.86998 2.10489 6.49997 2.35571C6.12996 2.60653 5.84157 2.96302 5.67127 3.38011C5.50098 3.7972 5.45642 4.25616 5.54323 4.69894C5.63005 5.14173 5.84434 5.54845 6.15901 5.86768C6.47368 6.18691 6.87459 6.4043 7.31105 6.49238C7.74751 6.58045 8.19991 6.53525 8.61104 6.36248C9.02217 6.18972 9.37358 5.89715 9.62081 5.52178C9.86804 5.14641 10 4.70509 10 4.25363C10 3.64824 9.76295 3.06765 9.34099 2.63958C8.91903 2.21151 8.34674 1.97102 7.75 1.97102Z"
        fill="#91AD2C"
      />
      <path
        d="M14.75 15.1594C14.5519 15.1568 14.3626 15.0758 14.2225 14.9337C14.0824 14.7915 14.0026 14.5995 14 14.3986C14 12.4203 12.94 11.1015 7.75 11.1015C2.56 11.1015 1.5 12.4203 1.5 14.3986C1.5 14.6003 1.42098 14.7939 1.28033 14.9366C1.13968 15.0793 0.948912 15.1594 0.75 15.1594C0.551088 15.1594 0.360322 15.0793 0.21967 14.9366C0.0790175 14.7939 0 14.6003 0 14.3986C0 9.57971 5.43 9.57971 7.75 9.57971C10.07 9.57971 15.5 9.57971 15.5 14.3986C15.4974 14.5995 15.4176 14.7915 15.2775 14.9337C15.1374 15.0758 14.9481 15.1568 14.75 15.1594Z"
        fill="#91AD2C"
      />
    </svg>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'UnAuthorized',

  computed: {
    ...mapState(['isPersonalCabinet']),
    ...mapGetters(['getLinkTarget']),
  },
};
</script>

<style scoped lang="less">
.unAuthorized {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  & span {
    cursor: pointer;
  }

  .title {
    display: flex;
    align-self: flex-start;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    position: relative; /* Для псевдоэлемента */
  }

  .title:hover::after {
    content: ''; /* Создаем линию */
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px; /* Сдвиг подчеркивания ниже текста */
    height: 1px;
    border-bottom: 1px dashed; /* Пунктирная линия */
  }

  .authorize {
    padding-top: 4px;
    color: #bebebe;
    font-style: italic;
  }
}

.user-image {
  top: 2px;
  position: relative;
  width: 18px;
  height: 18px;
  fill: none;
}
</style>
