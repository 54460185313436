<template>
  <div class="authorized">
    <div class="emailUpdates">
      <a
        :href="getHref('cabinet')"
        :target="getLinkTarget"
        class="email"
        :class="{underline: !isPersonalCabinet}"
      >
        {{ emailOrTitle }}
      </a>

      <a
        href="/auth#ready_to_update"
        :target="getLinkTarget"
        class="updates"
        :title="`Доступные обновления: ${updates} шт`"
        @click="goToUpdatesMetric"
      >
        <span>{{ updates }}</span>
        <img
          src="./img/bell.png"
          width="16"
          height="16"
          alt=""
        >
      </a>
    </div>

    <div class="balance">
      <a
        :href="getHref('balance')"
        :target="getLinkTarget"
        @click="upBalance"
      >
        баланс: {{ balance.toLocaleString('ru') }} руб.
      </a>
      <img
        v-if="isEmptyBalance"
        class="_popup balanceInstruction"
        src="./img/exp_question.png"
        width="16"
        height="16"
        alt=""
        data-file="/api/popup/balance"
        data-method="GET"
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Authorized',

  data() {
    return {
      openLkBalanceBlockEvent: null,
    };
  },

  computed: {
    ...mapState([
      'email',
      'updates',
      'balance',
      'isPersonalCabinet',
    ]),

    ...mapGetters([
      'getLinkTarget',
    ]),

    isEmptyBalance() {
      return this.balance === 0;
    },

    emailOrTitle() {
      return this.isPersonalCabinet ? this.email : 'Личный кабинет';
    },
  },

  created() {
    this.openLkBalanceBlockEvent = new Event('open-lk-balance-block');
  },

  methods: {
    goToUpdatesMetric() {
      window.MFuncs.yandexReachGoalWithTarget('goToUpdate');
    },

    getHref(type) {
      switch (type) {
        case 'cabinet':
          return '/auth';
        case 'balance':
          return '/auth#popup_balance';
        default:
          return '';
      }
    },

    upBalance() {
      if (this.isPersonalCabinet) {
        document.body.dispatchEvent(this.openLkBalanceBlockEvent);
      }
    },
  },
};
</script>

<style scoped lang="less">
.authorized {
  display: flex;
  flex-direction: column;

  .emailUpdates {
    font-size: 18px;
    display: flex;

    .email {
      display: inline-block;
      font-weight: 400;
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
      text-transform: none;
      min-height: 16px;
      min-width: 60px;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }

    .underline:hover {
      text-decoration: underline;
    }

    .updates {
      display: flex;
      font-weight: 400;
      background-color: var(--color-warning-light);
      color: #fff;
      padding: 6px 5px;
      border-radius: 5px;
      position: relative;
      top: -11px;
      right: -10px;
      text-transform: none;
      font-size: 14px;
      text-decoration: none;
      cursor: pointer;

      img {
        margin-left: 3px;
      }
    }
  }

  .balance {
    display: flex;
    position: relative;
    top: -6px;
    cursor: pointer;

    & a {
      opacity: .6;
      border-bottom: 1px dashed #fff;
      color: #fff;
      text-decoration: none;
      font-size: 13px;
    }

    & img {
      position: relative;
      margin-left: 7px;
      top: -1px;
    }
  }
}
</style>
