function catchError(e) {
  console.error(e);
  window.echo(
    'Произошла техническая ошибка. Наш телефон: <a href="tel:+78007752912" ' +
    'style="color: white">8 800 775-29-12</a>',
    120000,
      'error'
  );
}

export { catchError };
