<template>
  <div class="promocode">
    <p
      class="eb-button-prime"
      title="Скопировать"
      @click="copyPromocode"
    >
      {{ code }}
    </p>

    <p class="promocode__discount">
      {{ discount }}%
    </p>

    <p class="promocode__end-time">
      Дата окончания: {{ endTime }}
    </p>

    <p v-if="(minCost > 0)">
      <i>(для заказа от {{ minCost.toLocaleString('ru') }} руб.)</i>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PromocodeInfo',
  props: {
    code: {
      type: String,
      required: true,
    },

    endTime: {
      type: String,
      required: true,
    },

    discount: {
      type: Number,
      required: true,
    },

    minCost: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  methods: {
    copyPromocode() {
      navigator.clipboard.writeText(this.code);
      window.echo('Промокод скопирован', 6000, 'success');
    },
  },
};
</script>

<style scoped src="../../../../../../../css/elements.css"></style>

<style scoped>
  .promocode {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: var(--cart-padding);
    border-radius: var(--cart-border-radius);
    background-color: var(--cart-background-color);
    box-shadow: var(--cart-box-shadow);
    background: var(--background-color-configurator);
  }
</style>
