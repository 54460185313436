export default class OrderTypes {
    static get MAIN_BASE() {
        return 'main_base';
    }

    static get NEW_COMPANIES() {
        return 'new_companies';
    }

    static get EGRUL() {
        return 'egrul';
    }

    static get IMPEX() {
        return 'impex';
    }

    static get COMPANY_SEARCH() {
        return 'company_search_by_key';
    }

    static get ACTUALIZATION() {
        return 'actualization';
    }

    static get DBC_ORDER() {
        return 'dbc_order';
    }
}
