<template>
  <div class="outdatedOrder">
    <div class="left-block">
      <div>
        <b>{{ getOrderName }}</b>
      </div>
      <p>
        <span
          v-if="strategy==='dynamic'"
          class="update-info"
        >
          <span>Прошло с оплаты: <b>{{ timePassed }}</b></span>
          <span>Всего компаний: <b>{{ totalCount.toLocaleString('ru') }}</b></span>
          <span>Добавилось компаний: <b>{{
            updateCount.toLocaleString('ru')
          }} ({{ getNewCountPercent.toLocaleString('ru') }}%)</b></span>
        </span>
        <span
          v-if="strategy==='static'"
          class="update-info"
        >
          <span>Прошло с оплаты: <b>{{ timePassed }}</b></span>
          <span>Можно обновить заказ со скидкой <b>{{ discount * 100 }}%</b></span>
        </span>
      </p>
    </div>
    <div class="right-block">
      <div>
        Заказ № {{ orderId }}
      </div>
      <span
        v-if="strategy==='dynamic'"
        class="info-cost"
      >
        <b>{{ updateCost.toLocaleString('ru') }} руб.</b>
      </span>
      <div>
        <p
          class="eb-button-prime"
          title="Перейти к обновлению заказа"
          @click="goToReadyToUpdateOrders"
        >
          К обновлению
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OrderTypes from '@/lk/consts/OrderTypes';
import dayjs from 'dayjs';
import preciseDiff from 'dayjs-precise-range';
import plural from 'plural-ru';

export default {
  name: 'UpdatingOrderInfo',
  props: {
    orderId: {
      type: Number,
      required: true,
    },

    paymentDate: {
      type: String,
      required: false,
      default: null,
    },

    type: {
      type: String,
      required: true,
    },

    countCompanies: {
      type: Number,
      required: true,
    },

    discount: {
      type: Number,
      required: false,
      default: 0,
    },

    strategy: {
      type: String,
      required: false,
      default: '',
    },

    totalCount: {
      type: Number,
      required: false,
      default: 0,
    },

    updateCount: {
      type: Number,
      required: false,
      default: 0,
    },

    updateCost: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  emits: ['close'],

  computed: {
    getOrderName() {
      const names = {
        [OrderTypes.MAIN_BASE]: 'База компаний',
        [OrderTypes.EGRUL]: 'ЕГРЮЛ',
        [OrderTypes.NEW_COMPANIES]: 'Новые компании',
        [OrderTypes.IMPEX]: 'Импорт/Экспорт',
        [OrderTypes.COMPANY_SEARCH]: 'Поиск по словам',
        [OrderTypes.ACTUALIZATION]: 'Обогащение базы',
        [OrderTypes.DBC_ORDER]: 'Магазин баз',
      };

      return names[this.type];
    },

    timePassed() {
      dayjs.extend(preciseDiff);
      const startDate = dayjs(this.paymentDate);
      const endDate = dayjs(new Date());
      const diff = dayjs.preciseDiff(startDate, endDate, true);
      return this.getFormatDiff(diff);
    },

    getNewCountPercent() {
      if (
        !Number.isNaN(this.updateCount)
        && !Number.isNaN(this.countCompanies)
        && this.countCompanies !== 0
      ) {
        return Math.round((this.updateCount / this.countCompanies) * 100);
      }
      return 0;
    },
  },

  methods: {
    getFormatDiff(diff) {
      let formattedDiff = '';

      if (diff.years > 0) {
        formattedDiff += `${diff.years} ${plural(diff.years, 'год', 'года', 'лет')} `;
      }
      if (diff.months > 0) {
        formattedDiff += `${diff.months} ${plural(diff.months, 'месяц', 'месяца', 'месяцев')} `;
      }
      if (diff.days > 0) {
        formattedDiff += `${diff.days} ${plural(diff.days, 'день', 'дня', 'дней')}`;
      }
      return formattedDiff;
    },

    goToReadyToUpdateOrders() {
      window.location.href = '/auth#ready_to_update';
      this.$emit('close');
    },
  },
};
</script>

<style scoped src="../../../../../../../css/elements.css"></style>

<style scoped>
.outdatedOrder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  padding: var(--cart-padding);
  border-radius: var(--cart-border-radius);
  box-shadow: var(--cart-box-shadow);
  background: var(--background-color-configurator);
}

.left-block, .right-block, .update-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.right-block {
  align-items: end;
}
</style>
